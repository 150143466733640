import React from "react";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import PersonalTaxPrep from "../components/Form/PersonalTaxPrep";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Personal Tax Preparation | DeBlanc + Murphy"
        description="Streamline your tax filing with DeBlanc Murphy's personal tax prep services. Get expert help and a free quote today!"
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="pb-20 pt-12 md:pb-32 md:pt-16">
        <div className="container">
          <header className="mb-8 text-center">
            <h1 className="mb-3">Personal Tax Preparation</h1>
            <p className="md:text-xl">
              Relax and let us handle the tax returns for you.
            </p>
          </header>

          <div className="relative mx-auto min-h-[460px] max-w-[960px] rounded-3xl border border-primary-500 bg-gray-800 px-6 py-20 md:px-12 md:py-26">
            <PersonalTaxPrep />
          </div>
        </div>
      </section>
    </Layout>
  );
};

// export const data = graphql`
// {

//  }
// `;

export default Page;
