import React, { useState } from "react";

import BackButton from "../Form/BackButton";
import ButtonSolid from "../Button/ButtonSolid";

function encode(data) {
  const formData = new FormData();
  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }
  return formData;
}

const invisible = "hidden";
const visible = "block";

const Form = () => {
  const [state, setState] = useState({});
  let [incomeSources, setIncomeSources] = useState([]);
  let [employerWages, setEmployerWages] = useState(false);
  let [interestIncome, setInterestIncome] = useState(false);
  let [dividendIncome, setDividendIncome] = useState(false);
  let [capitalGainsLoss, setCapitalGainsLoss] = useState(false);
  let [realEstateSales, setRealEstateSales] = useState(false);
  let [rentalIncome, setRentalIncome] = useState(false);
  let [cryptocurrency, setCryptocurrency] = useState(false);
  let [partnerships, setPartnerships] = useState(false);
  let [businessIncome, setBusinessIncome] = useState(false);
  let [governmentPayments, setGovernmentPayments] = useState(false);
  let [miscellaneousIncome, setMiscellaneousIncome] = useState(false);
  let [foreignIncome, setForeignIncome] = useState(false);
  let [retirementDistributions, setRetirementDistributions] = useState(false);
  let [other, setOther] = useState(false);
  let [calculatorData, setCalculatorData] = useState([]);
  const [lowQuote, setLowQuote] = useState(0);
  const [highQuote, setHighQuote] = useState(0);
  const [currentStep, setCurrentStep] = useState(1);
  const [step1, setStep1] = useState(visible);
  const [step2, setStep2] = useState(invisible);
  const [step3, setStep3] = useState(invisible);
  const [step4, setStep4] = useState(invisible);
  const [step5, setStep5] = useState(invisible);
  const [step6, setStep6] = useState(invisible);
  const [step7, setStep7] = useState(invisible);
  const [steps, setSteps] = useState(visible);
  const [confirmation, setConfirmation] = useState(invisible);
  const totalSteps = 7;

  const handleChange = (e) => {
    if (e.target.type === "checkbox") {
      setState((state) => {
        let array = state[e.target.name] || [];
        array =
          e.target.checked === true
            ? [...array, e.target.value]
            : array.filter((element) => element !== e.target.value);
        return {
          ...state,
          [e.target.name]: array,
        };
      });
    } else if (e.target.getAttribute("data-multiplier")) {
      let exists = false;

      let updatedItemList = calculatorData.map((item) => {
        if (item.question === e.target.getAttribute("data-question")) {
          exists = true;
          return {
            ...item,
            answer: e.target.value,
            value: Number(
              e.target.value * e.target.getAttribute("data-multiplier")
            ),
          };
        }
        return item;
      });

      if (!exists) {
        updatedItemList.push({
          question: e.target.getAttribute("data-question"),
          answer: e.target.value,
          value: Number(
            e.target.value * e.target.getAttribute("data-multiplier")
          ),
        });
      }

      setCalculatorData(updatedItemList);
    } else {
      setState((state) => ({
        ...state,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const handleFileUpload = (e) => {
    setState((state) => ({
      ...state,
      [e.target.name]: e.target.files[0],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(
        () => setConfirmation(visible),
        console.log(state),
        setSteps(invisible),
        form.remove(),
        (window.dataLayer = window.dataLayer || []),
        window.dataLayer.push({
          event: "personalTaxPrepFormSubmission",
        })
      )
      .catch((error) => alert(error));
  };

  const checkIncomeSources = (e) => {
    setState((state) => {
      let array = state[e.target.name] || [];
      array =
        e.target.checked === true
          ? [...array, e.target.value]
          : array.filter((element) => element !== e.target.value);
      return {
        ...state,
        [e.target.name]: array,
      };
    });

    let exists = false;

    let incomeSourcesList = incomeSources.map((item) => {
      if (item.source === e.target.getAttribute("value")) {
        exists = true;
        return {
          ...item,
          source: e.target.getAttribute("value"),
        };
      }
      return item;
    });

    if (exists) {
      let arrayIndex = incomeSourcesList.indexOf(
        e.target.getAttribute("value")
      );
      incomeSourcesList.splice(arrayIndex, 1);
    } else {
      incomeSourcesList.push({
        source: e.target.getAttribute("value"),
      });
    }
    setIncomeSources(incomeSourcesList);
  };

  const step1Next = (e) => {
    setCurrentStep(2);
    setStep1(invisible);
    setStep2(visible);

    incomeSources.filter((e) => e.source === "Employer Wages").length > 0
      ? setEmployerWages(true)
      : setEmployerWages(false);

    incomeSources.filter((e) => e.source === "Interest Income").length > 0
      ? setInterestIncome(true)
      : setInterestIncome(false);

    incomeSources.filter((e) => e.source === "Dividend Income").length > 0
      ? setDividendIncome(true)
      : setDividendIncome(false);

    incomeSources.filter((e) => e.source === "Capital Gains / Loses").length > 0
      ? setCapitalGainsLoss(true)
      : setCapitalGainsLoss(false);

    incomeSources.filter((e) => e.source === "Real Estate Sales").length > 0
      ? setRealEstateSales(true)
      : setRealEstateSales(false);

    incomeSources.filter((e) => e.source === "Rental Income").length > 0
      ? setRentalIncome(true)
      : setRentalIncome(false);

    incomeSources.filter((e) => e.source === "Cryptocurrency").length > 0
      ? setCryptocurrency(true)
      : setCryptocurrency(false);

    incomeSources.filter((e) => e.source === "Partnerships").length > 0
      ? setPartnerships(true)
      : setPartnerships(false);

    incomeSources.filter((e) => e.source === "Business Income").length > 0
      ? setBusinessIncome(true)
      : setBusinessIncome(false);

    incomeSources.filter((e) => e.source === "Government Payments").length > 0
      ? setGovernmentPayments(true)
      : setGovernmentPayments(false);

    incomeSources.filter((e) => e.source === "Miscellaneous Income").length > 0
      ? setMiscellaneousIncome(true)
      : setMiscellaneousIncome(false);

    incomeSources.filter((e) => e.source === "Foreign Income").length > 0
      ? setForeignIncome(true)
      : setForeignIncome(false);

    incomeSources.filter((e) => e.source === "Retirement Distributions")
      .length > 0
      ? setRetirementDistributions(true)
      : setRetirementDistributions(false);

    incomeSources.filter((e) => e.source === "Other").length > 0
      ? setOther(true)
      : setOther(false);
  };
  const step2Back = () => {
    setCurrentStep(1);
    setStep2(invisible);
    setStep1(visible);
  };

  const step2Next = (e) => {
    setCurrentStep(3);
    setStep2(invisible);
    setStep3(visible);
  };

  const step3Back = () => {
    setCurrentStep(2);
    setStep3(invisible);
    setStep2(visible);
  };

  const step3Next = (e) => {
    setCurrentStep(4);
    setStep3(invisible);
    setStep4(visible);

    let exists = false;

    let updatedItemList = calculatorData.map((item) => {
      if (item.question === e.target.getAttribute("data-question")) {
        exists = true;
        return {
          ...item,
          answer: e.target.getAttribute("data-answer"),
          value: Number(e.target.value),
        };
      }
      return item;
    });

    if (!exists) {
      updatedItemList.push({
        question: e.target.getAttribute("data-question"),
        answer: e.target.getAttribute("data-answer"),
        value: Number(e.target.value),
      });
    }

    setCalculatorData(updatedItemList);
  };

  const step4Back = () => {
    setCurrentStep(3);
    setStep4(invisible);
    setStep3(visible);
  };

  const step4Next = (e) => {
    setCurrentStep(5);
    setStep4(invisible);
    setStep5(visible);

    let exists = false;

    let updatedItemList = calculatorData.map((item) => {
      if (item.question === e.target.getAttribute("data-question")) {
        exists = true;
        return {
          ...item,
          answer: e.target.getAttribute("data-answer"),
          value: Number(e.target.value),
        };
      }
      return item;
    });

    if (!exists) {
      updatedItemList.push({
        question: e.target.getAttribute("data-question"),
        answer: e.target.getAttribute("data-answer"),
        value: Number(e.target.value),
      });
    }

    setCalculatorData(updatedItemList);
  };

  const step5Back = () => {
    setCurrentStep(4);
    setStep5(invisible);
    setStep4(visible);
  };

  const step5Next = (e) => {
    setCurrentStep(6);
    setStep5(invisible);
    setStep6(visible);

    let exists = false;

    let updatedItemList = calculatorData.map((item) => {
      if (item.question === e.target.getAttribute("data-question")) {
        exists = true;
        return {
          ...item,
          answer: e.target.getAttribute("data-answer"),
          value: Number(e.target.value),
        };
      }
      return item;
    });

    if (!exists) {
      updatedItemList.push({
        question: e.target.getAttribute("data-question"),
        answer: e.target.getAttribute("data-answer"),
        value: Number(e.target.value),
      });
    }

    setCalculatorData(updatedItemList);
  };

  const step6Back = () => {
    setCurrentStep(5);
    setStep6(invisible);
    setStep5(visible);
  };

  const step6Next = () => {
    setCurrentStep(7);
    setStep6(invisible);
    setStep7(visible);

    // Calculate Quote
    let valueTotal = calculatorData.reduce(
      (total, currentValue) => total + currentValue.value,
      0
    );

    let adminFee = Math.round(valueTotal * 0.1);
    let total = valueTotal + adminFee;
    let low_quote;
    let high_quote;

    if (total < 750) {
      low_quote = 750;
      high_quote = 850;
    } else {
      low_quote = total - total * 0.1;
      high_quote = total + total * 0.15;
      low_quote = Math.floor(low_quote / 100).toFixed() * 100;
    high_quote = Math.ceil(high_quote / 100).toFixed() * 100;
    }

    

    setLowQuote(low_quote);
    setHighQuote(high_quote);

    setState((state) => ({
      ...state,
      quote: "$" + low_quote + "-" + high_quote,
    }));

    setState((state) => ({
      ...state,
      answers: calculatorData
        .map((item) => {
          return item.question + " " + item.answer + "\n";
        })
        .join(""),
    }));
  };

  const step7Back = () => {
    setCurrentStep(6);
    setStep7(invisible);
    setStep6(visible);
  };

  return (
    <>
      <div className={`${confirmation}`}>
        <header className="mx-auto max-w-[460px] text-center">
          <div className="heading-two">Price Estimate</div>
          <div className="mb-6 text-3xl font-bold text-primary-500 md:mb-8 md:text-4xl">
            {`$${lowQuote}`} - {`$${highQuote}`}
          </div>
          <div className="mb-8">
            <ButtonSolid modal="modal-contact" text="Talk To An Advisor" />
          </div>
          <p className="mb-0 text-sm italic">
            This is a ballpark estimate based on your responses and may not
            include all elements of the engagement. Therefore, the actual fee
            may vary.
          </p>
        </header>
      </div>

      <div className={`absolute top-14 left-0 right-0 ${steps}`}>
        <div className="text-center text-sm font-light uppercase tracking-wide text-gray-300">
          Step {currentStep} of {totalSteps}
        </div>
      </div>

      <form
        name="Personal Tax Preparation"
        method="post"
        action=""
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input
          type="hidden"
          name="form-name"
          value="Personal Tax Preparation"
        />
        <div hidden>
          <label>
            Don’t fill this out:{" "}
            <input name="bot-field" onChange={handleChange} />
          </label>
        </div>

        {/* Step 1 */}
        <div className={`${step1}`}>
          <div className="mx-auto max-w-[540px]">
            <fieldset className="mb-8">
              <legend className="mb-10 text-center font-heading text-lg font-semibold text-typography-heading/70 md:mb-10">
                Please select your expected sources of income
              </legend>

              <div className="grid gap-y-4 gap-x-4 grid-cols-2">
                <div className="flex space-y-4 flex-col">
                  <label className="relative inline-flex cursor-pointer items-center pl-8 text-sm md:text-base">
                    <input
                      type="checkbox"
                      name="Please select your expected sources of income"
                      value="Employer Wages"
                      onChange={checkIncomeSources}
                    />
                    <span className="font-semibold text-white">
                      Employer Wages
                    </span>
                    <span className="pricing-tool-checkbox"></span>
                  </label>

                  <label className="relative inline-flex cursor-pointer items-center pl-8 text-sm md:text-base">
                    <input
                      type="checkbox"
                      name="Please select your expected sources of income"
                      value="Interest Income"
                      onChange={checkIncomeSources}
                    />
                    <span className="font-semibold text-white">
                      Interest Income
                    </span>
                    <span className="pricing-tool-checkbox"></span>
                  </label>

                  <label className="relative inline-flex cursor-pointer items-center pl-8 text-sm md:text-base">
                    <input
                      type="checkbox"
                      name="Please select your expected sources of income"
                      value="Dividend Income"
                      onChange={checkIncomeSources}
                    />
                    <span className="font-semibold text-white">
                      Dividend Income
                    </span>
                    <span className="pricing-tool-checkbox"></span>
                  </label>

                  <label className="relative inline-flex cursor-pointer items-center pl-8 text-sm md:text-base">
                    <input
                      type="checkbox"
                      name="Please select your expected sources of income"
                      value="Capital Gains / Loses"
                      onChange={checkIncomeSources}
                    />
                    <span className="font-semibold text-white">
                      Capital Gains / Loses
                    </span>
                    <span className="pricing-tool-checkbox"></span>
                  </label>

                  <label className="relative inline-flex cursor-pointer items-center pl-8 text-sm md:text-base">
                    <input
                      type="checkbox"
                      name="Please select your expected sources of income"
                      value="Real Estate Sales"
                      onChange={checkIncomeSources}
                    />
                    <span className="font-semibold text-white">
                      Real Estate Sales
                    </span>
                    <span className="pricing-tool-checkbox"></span>
                  </label>

                  <label className="relative inline-flex cursor-pointer items-center pl-8 text-sm md:text-base">
                    <input
                      type="checkbox"
                      name="Please select your expected sources of income"
                      value="Rental Income"
                      onChange={checkIncomeSources}
                    />
                    <span className="font-semibold text-white">
                      Rental Income
                    </span>
                    <span className="pricing-tool-checkbox"></span>
                  </label>

                  <label className="relative inline-flex cursor-pointer items-center pl-8 text-sm md:text-base">
                    <input
                      type="checkbox"
                      name="Please select your expected sources of income"
                      value="Cryptocurrency"
                      onChange={checkIncomeSources}
                    />
                    <span className="font-semibold text-white">
                      Cryptocurrency
                    </span>
                    <span className="pricing-tool-checkbox"></span>
                  </label>
                </div>

                <div className="flex space-y-4 flex-col">
                  <label className="relative inline-flex cursor-pointer items-center pl-8 text-sm md:text-base">
                    <input
                      type="checkbox"
                      name="Please select your expected sources of income"
                      value="Partnerships"
                      onChange={checkIncomeSources}
                    />
                    <span className="font-semibold text-white">
                    Partnerships/S-Corps/Trusts
                    </span>
                    <span className="pricing-tool-checkbox"></span>
                  </label>

                  <label className="relative inline-flex cursor-pointer items-center pl-8 text-sm md:text-base">
                    <input
                      type="checkbox"
                      name="Please select your expected sources of income"
                      value="Business Income"
                      onChange={checkIncomeSources}
                    />
                    <span className="font-semibold text-white">
                    Other Business Income/Single-Member LLC
                    </span>
                    <span className="pricing-tool-checkbox"></span>
                  </label>

                  <label className="relative inline-flex cursor-pointer items-center pl-8 pr-8 text-sm md:text-base">
                    <input
                      type="checkbox"
                      name="Please select your expected sources of income"
                      value="Government Payments"
                      onChange={checkIncomeSources}
                    />
                    <span className="font-semibold text-white">
                      Government Payments
                    </span>
                    <span className="pricing-tool-checkbox"></span>
                  </label>

                  <label className="relative inline-flex cursor-pointer items-center pl-8 pr-8 text-sm md:text-base">
                    <input
                      type="checkbox"
                      name="Please select your expected sources of income"
                      value="Miscellaneous Income"
                      onChange={checkIncomeSources}
                    />
                    <span className="font-semibold text-white">
                      Miscellaneous Income
                    </span>
                    <span className="pricing-tool-checkbox"></span>
                  </label>

                  <label className="relative inline-flex cursor-pointer items-center pl-8 pr-8 text-sm md:text-base">
                    <input
                      type="checkbox"
                      name="Please select your expected sources of income"
                      value="Foreign Income"
                      onChange={checkIncomeSources}
                    />
                    <span className="font-semibold text-white">
                      Foreign Income
                    </span>
                    <span className="pricing-tool-checkbox"></span>
                  </label>

                  <label className="relative inline-flex cursor-pointer items-center pl-8 pr-8 text-sm md:text-base">
                    <input
                      type="checkbox"
                      name="Please select your expected sources of income"
                      value="Retirement Distributions"
                      onChange={checkIncomeSources}
                    />
                    <span className="font-semibold text-white">
                      Retirement Distributions
                    </span>
                    <span className="pricing-tool-checkbox"></span>
                  </label>

                  <label className="relative inline-flex cursor-pointer items-center pl-8 pr-8 text-sm md:text-base">
                    <input
                      type="checkbox"
                      name="Please select your expected sources of income"
                      value="Other"
                      onChange={checkIncomeSources}
                    />
                    <span className="font-semibold text-white">Other</span>
                    <span className="pricing-tool-checkbox"></span>
                  </label>
                </div>
              </div>
            </fieldset>

            <div className="text-center">
              <ButtonSolid
                onClick={() => {
                  step1Next();
                }}
                type="button"
                text="Next"
                className="min-w-[284px]"
              />
            </div>
          </div>
        </div>

        {/* Step 2 */}
        <div className={`${step2}`}>
          <div className="mx-auto max-w-[744px]">
            <fieldset className="mb-4">
              <legend className="mx-auto mb-10 max-w-[512px] text-center font-heading text-lg font-semibold text-typography-heading/70 md:mb-10">
                Based on your selected income sources, please provide the
                following information
              </legend>

              {employerWages && (
                <label className="relative flex items-start justify-between">
                  <div>
                    <span className="font-bold text-white">Employer Wages</span>
                    <p className="text-sm">
                      How many W2 forms do you expect to receive from employer
                      wages?
                    </p>
                  </div>

                  <input
                    type="number"
                    data-question="How many W2 forms do you expect to receive from employer wages?"
                    data-answer=""
                    data-multiplier={50}
                    min="1"
                    required
                    className="h-12 w-20 rounded border border-gray-700 bg-black text-typography-body"
                    onChange={handleChange}
                  />
                </label>
              )}

              {interestIncome && (
                <label className="relative flex items-start justify-between">
                  <div>
                    <span className="font-bold text-white">
                      Interest Income
                    </span>
                    <p className="text-sm">
                      How many 1099-INT forms do you expect to receive from
                      interest bearing accounts (checking, savings, money
                      market, bonds, brokerage accounts)?
                    </p>
                  </div>

                  <input
                    type="number"
                    data-question="How many 1099-INT forms do you expect to receive from interest bearing accounts (checking, savings, money market, bonds, brokerage accounts)?"
                    data-answer=""
                    data-multiplier={50}
                    min="1"
                    required
                    className="h-12 w-20 rounded border border-gray-700 bg-black text-typography-body"
                    onChange={handleChange}
                  />
                </label>
              )}

              {dividendIncome && (
                <label className="relative flex items-start justify-between">
                  <div>
                    <span className="font-bold text-white">
                      Dividend Income
                    </span>
                    <p className="text-sm">
                      How many 1099-DIV forms do you expect to receive from
                      dividend paying investment accounts?
                    </p>
                  </div>

                  <input
                    type="number"
                    data-question="How many 1099-DIV forms do you expect to receive from dividend paying investment accounts?"
                    data-answer=""
                    data-multiplier={50}
                    min="1"
                    required
                    className="h-12 w-20 rounded border border-gray-700 bg-black text-typography-body"
                    onChange={handleChange}
                  />
                </label>
              )}

              {capitalGainsLoss && (
                <label className="relative flex items-start justify-between">
                  <div>
                    <span className="font-bold text-white">
                      Capital Gains / Loses
                    </span>
                    <p className="text-sm">
                      How many different brokerage accounts did you have that
                      reported gains or losses?
                    </p>
                  </div>

                  <input
                    type="number"
                    data-question="How many different brokerage accounts did you have that reported gains or losses?"
                    data-answer=""
                    data-multiplier={149}
                    min="1"
                    required
                    className="h-12 w-20 rounded border border-gray-700 bg-black text-typography-body"
                    onChange={handleChange}
                  />
                </label>
              )}

              {realEstateSales && (
                <label className="relative flex items-start justify-between">
                  <div>
                    <span className="font-bold text-white">
                      Real Estate Sales
                    </span>
                    <p className="text-sm">
                      How many real estate assets did you sell?
                    </p>
                  </div>

                  <input
                    type="number"
                    data-question="How many real estate assets did you sell?"
                    data-answer=""
                    data-multiplier={249}
                    min="1"
                    required
                    className="h-12 w-20 rounded border border-gray-700 bg-black text-typography-body"
                    onChange={handleChange}
                  />
                </label>
              )}

              {rentalIncome && (
                <label className="relative flex items-start justify-between">
                  <div>
                    <span className="font-bold text-white">Rental Income</span>
                    <p className="text-sm">
                      How many rental properties do you own?
                    </p>
                  </div>

                  <input
                    type="number"
                    data-question="How many rental properties do you own?"
                    data-answer=""
                    data-multiplier={249}
                    min="1"
                    required
                    className="h-12 w-20 rounded border border-gray-700 bg-black text-typography-body"
                    onChange={handleChange}
                  />
                </label>
              )}

              {cryptocurrency && (
                <div>
                  <label className="relative flex items-start justify-between">
                    <div>
                      <span className="font-bold text-white">
                        Cryptocurrency
                      </span>
                      <p className="text-sm">
                        Did you sell any cryptocurrency, or convert one
                        cryptocurrency to another?
                      </p>
                    </div>

                    <input
                      type="text"
                      value="YES"
                      readOnly
                      data-question="Did you sell any cryptocurrency, or convert one cryptocurrency to another?"
                      data-answer="yes"
                      data-multiplier={202}
                      className="h-12 w-20 rounded border border-gray-700 bg-black text-white text-center"
                      onChange={handleChange}
                    />
                  </label>
                </div>
              )}

              {partnerships && (
                <label className="relative flex items-start justify-between">
                  <div>
                    <span className="font-bold text-white">Partnerships/S-Corps/Trusts</span>
                    <p className="text-sm">
                    How many K-1s do you expect to receive this year from Partnerships, S-Corporations, and Trusts?
                    </p>
                  </div>

                  <input
                    type="number"
                    data-question="How many K-1 do you expect to receive this year from Partnerships, S-Corporations, and Trust?"
                    data-answer=""
                    data-multiplier={249}
                    min="1"
                    required
                    className="h-12 w-20 rounded border border-gray-700 bg-black text-typography-body"
                    onChange={handleChange}
                  />
                </label>
              )}

              {businessIncome && (
                <label className="relative flex items-start justify-between">
                  <div>
                    <span className="font-bold text-white">
                    Other Business Income
                    </span>
                    <p className="text-sm">How many unincorporated businesses or Single-Member LLCs do you have to report on Schedule C?</p>
                  </div>

                  <input
                    type="number"
                    data-question="How many businesses do you have?"
                    data-answer=""
                    data-multiplier={249}
                    min="1"
                    required
                    className="h-12 w-20 rounded border border-gray-700 bg-black text-typography-body"
                    onChange={handleChange}
                  />
                </label>
              )}

              {governmentPayments && (
                <label className="relative flex items-start justify-between">
                  <div>
                    <span className="font-bold text-white">
                      Government Payments
                    </span>
                    <p className="text-sm">
                      How many 1099-G forms do you expect to receive for any
                      government payments, including unemployment income and
                      state tax refunds?
                    </p>
                  </div>

                  <input
                    type="number"
                    data-question="How many 1099-G forms do you expect to receive for any government payments, including unemployment income and state tax refunds?"
                    data-answer=""
                    data-multiplier={99}
                    min="1"
                    required
                    className="h-12 w-20 rounded border border-gray-700 bg-black text-typography-body"
                    onChange={handleChange}
                  />
                </label>
              )}

              {miscellaneousIncome && (
                <label className="relative flex items-start justify-between">
                  <div>
                    <span className="font-bold text-white">
                      Miscellaneous Income
                    </span>
                    <p className="text-sm">
                      How many 1099-MISC forms do you expect to receive for
                      other miscellaneous income?
                    </p>
                  </div>

                  <input
                    type="number"
                    data-question="How many 1099-MISC forms do you expect to receive for other miscellaneous income?"
                    data-answer=""
                    data-multiplier={50}
                    min="1"
                    required
                    className="h-12 w-20 rounded border border-gray-700 bg-black text-typography-body"
                    onChange={handleChange}
                  />
                </label>
              )}

              {foreignIncome && (
                <label className="relative flex items-start justify-between">
                  <div>
                    <span className="font-bold text-white">Foreign Income</span>
                    <p className="text-sm">
                      Do you have any foreign sourced income or pay any foreign
                      tax?
                    </p>
                  </div>

                  <input
                    type="text"
                    value="YES"
                    readOnly
                    data-question="Do you have any foreign sourced income or pay any foreign tax?"
                    data-answer="yes"
                    data-multiplier={249}
                    min="1"
                    required
                    className="h-12 w-20 rounded border border-gray-700 bg-black text-white text-center"
                    onChange={handleChange}
                  />
                </label>
              )}

              {retirementDistributions && (
                <label className="relative flex items-start justify-between">
                  <div>
                    <span className="font-bold text-white">
                      Retirement Distributions
                    </span>
                    <p className="text-sm">
                      How many 1099-R forms do you expect to receive for
                      retirement account distributions?
                    </p>
                  </div>

                  <input
                    type="number"
                    data-question="How many 1099-R forms do you expect to receive for retirement account distributions?"
                    data-answer=""
                    data-multiplier={50}
                    min="1"
                    required
                    className="h-12 w-20 rounded border border-gray-700 bg-black text-typography-body"
                    onChange={handleChange}
                  />
                </label>
              )}

              {other && (
                <label className="relative flex items-start justify-between">
                  <div>
                    <span className="font-bold text-white">Other</span>
                    <p className="text-sm">
                      Do you have any other income items not previously listed?
                    </p>
                  </div>

                  <input
                    type="text"
                    value="YES"
                    readOnly
                    data-question="Do you have any other income items not previously listed?"
                    data-answer="yes"
                    data-multiplier={50}
                    min="1"
                    required
                    className="h-12 w-20 rounded border border-gray-700 bg-black text-white text-center"
                    onChange={handleChange}
                  />
                </label>
              )}

              <label className="relative flex items-start justify-between">
                <div>
                  <span className="font-bold text-white">States</span>
                  <p className="text-sm">
                    How many states do you expect to file in?
                  </p>
                </div>

                <input
                  type="number"
                  data-question="How many states do you expect to file in?"
                  data-answer=""
                  data-multiplier={249}
                  min="1"
                  required
                  max="50"
                  className="h-12 w-20 rounded border border-gray-700 bg-black text-typography-body"
                  onChange={handleChange}
                />
              </label>
            </fieldset>

            <div className="text-center">
              <ButtonSolid
                onClick={() => {
                  step2Next();
                }}
                type="button"
                text="Next"
                className="min-w-[284px]"
              />
            </div>
          </div>
          <BackButton onClick={step2Back} />
        </div>

        {/* Step 3 */}

        <div className={`${step3}`}>
          <div className="mx-auto max-w-[744px] text-center">
            <fieldset>
              <legend className="mb-10 font-heading text-xl md:mb-14 md:text-3xl">
                Do you itemize or take the standard deduction?
              </legend>

              <div className="inline-flex flex-col space-y-4">
                <label className="cursor-pointer">
                  <input
                    type="radio"
                    data-question="Do you itemize or take the standard deduction?"
                    data-answer="Itemized"
                    value={249}
                    className="absolute opacity-0"
                    onClick={step3Next}
                    required={true}
                  />
                  <div className="multistep-form-radios">Itemized</div>
                </label>

                <label className="cursor-pointer">
                  <input
                    type="radio"
                    data-question="Do you itemize or take the standard deduction?"
                    data-answer="Standard"
                    value={0}
                    className="absolute opacity-0"
                    onClick={step3Next}
                  />
                  <div className="multistep-form-radios">Standard</div>
                </label>
              </div>
            </fieldset>
          </div>
          <BackButton onClick={step3Back} />
        </div>

        {/* Step 4 */}
        <div className={`${step4}`}>
          <div className="mx-auto max-w-[744px] text-center">
            <fieldset>
              <legend className="mb-10 font-heading text-xl md:mb-14 md:text-3xl">
                Do you need to file an extension?
              </legend>

              <div className="inline-flex flex-col space-y-4">
                <label className="cursor-pointer">
                  <input
                    type="radio"
                    data-question="Do you need to file an extension?"
                    data-answer="Yes"
                    value={249}
                    className="absolute opacity-0"
                    onClick={step4Next}
                    required={true}
                  />
                  <div className="multistep-form-radios">Yes</div>
                </label>

                <label className="cursor-pointer">
                  <input
                    type="radio"
                    data-question="Do you need to file an extension?"
                    data-answer="No"
                    value={0}
                    className="absolute opacity-0"
                    onClick={step4Next}
                  />
                  <div className="multistep-form-radios">No</div>
                </label>
              </div>
            </fieldset>
          </div>
          <BackButton onClick={step4Back} />
        </div>

        {/* Step 5 */}
        <div className={`${step5}`}>
          <div className="mx-auto max-w-[744px] text-center">
            <fieldset>
              <legend className="mb-10 font-heading text-xl md:mb-14 md:text-3xl">
                Did you pay estimated tax payments and/or need them prepared?
              </legend>

              <div className="inline-flex flex-col space-y-4">
                <label className="cursor-pointer">
                  <input
                    type="radio"
                    data-question="Did you pay estimated tax payments and/or need them prepared?"
                    data-answer="Yes"
                    value={249}
                    className="absolute opacity-0"
                    onClick={step5Next}
                    required={true}
                  />
                  <div className="multistep-form-radios">Yes</div>
                </label>

                <label className="cursor-pointer">
                  <input
                    type="radio"
                    data-question="Did you pay estimated tax payments and/or need them prepared?"
                    data-answer="No"
                    value={0}
                    className="absolute opacity-0"
                    onClick={step5Next}
                  />
                  <div className="multistep-form-radios">No</div>
                </label>
              </div>
            </fieldset>
          </div>
          <BackButton onClick={step5Back} />
        </div>

        {/* Step 6 */}
        <div className={`${step6}`}>
          <div className="mx-auto max-w-[744px] text-center">
            <label
              className="mb-5 block font-heading text-xl md:text-3xl"
              htmlFor="please-attach-last-years-tax-return"
            >
              Please attach last year’s tax return
            </label>

            <div className="mx-auto mb-12 max-w-[351px] rounded-2xl border-2 border-dotted border-primary-500 bg-gray-700 px-6 py-10 text-center">
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mx-auto mb-7"
              >
                <path
                  d="M14 11L20.964 15.062L17.991 15.912L20.116 19.593L18.384 20.593L16.259 16.913L14.036 19.063L14 11ZM12 4H14V6H19C19.2652 6 19.5196 6.10536 19.7071 6.29289C19.8946 6.48043 20 6.73478 20 7V11H18V8H8V18H12V20H7C6.73478 20 6.48043 19.8946 6.29289 19.7071C6.10536 19.5196 6 19.2652 6 19V14H4V12H6V7C6 6.73478 6.10536 6.48043 6.29289 6.29289C6.48043 6.10536 6.73478 6 7 6H12V4ZM2 12V14H0V12H2ZM2 8V10H0V8H2ZM2 4V6H0V4H2ZM2 0V2H0V0H2ZM6 0V2H4V0H6ZM10 0V2H8V0H10ZM14 0V2H12V0H14Z"
                  fill="#86BC25"
                />
              </svg>

              <div className="mb-6 font-semibold text-gray-50">
                Drag & Drop or upload your files
              </div>
              <div className="flex w-full items-center justify-center">
                <input
                  type="file"
                  name="please-attach-last-years-tax-return"
                  onChange={handleFileUpload}
                />
              </div>
            </div>

            <ButtonSolid
              onClick={() => {
                step6Next();
              }}
              type="button"
              text="Continue"
            />
          </div>
          <BackButton onClick={step6Back} />
        </div>

        {/* Step 7 */}
        <div className={`${step7}`}>
          <div className="mx-auto max-w-[744px]">
            <header className="mb-8 text-center md:mb-10">
              <p className="font-heading text-xl md:text-3xl">
                Contact Information
              </p>
            </header>
            <div className="mb-12 grid gap-y-6 md:grid-cols-2 md:gap-x-6">
              <div>
                <label
                  className="mb-1 block font-body text-sm font-semibold text-typography-heading"
                  htmlFor="first-name"
                >
                  First Name
                </label>
                <input
                  type="text"
                  name="first-name"
                  onChange={handleChange}
                  required={true}
                  className="w-full rounded-sm border border-solid border-gray-50 bg-transparent px-4 py-2.5 text-typography-body transition-colors duration-300 ease-linear focus:border-transparent focus:outline-none focus:ring-3 focus:ring-primary-500/50"
                />
              </div>

              <div>
                <label
                  className="mb-1 block font-body text-sm font-semibold text-typography-heading"
                  htmlFor="last-name"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  name="last-name"
                  onChange={handleChange}
                  required={true}
                  className="w-full rounded-sm border border-solid border-gray-50 bg-transparent px-4 py-2.5 text-typography-body transition-colors duration-300 ease-linear focus:border-transparent focus:outline-none focus:ring-3 focus:ring-primary-500/50"
                />
              </div>

              <div>
                <label
                  className="mb-1 block font-body text-sm font-semibold text-typography-heading"
                  htmlFor="phone-number"
                >
                  Phone
                </label>
                <input
                  type="tel"
                  name="phone-number"
                  onChange={handleChange}
                  required={true}
                  className="w-full rounded-sm border border-solid border-gray-50 bg-transparent px-4 py-2.5 text-typography-body transition-colors duration-300 ease-linear focus:border-transparent focus:outline-none focus:ring-3 focus:ring-primary-500/50"
                />
              </div>

              <div>
                <label
                  className="mb-1 block font-body text-sm font-semibold text-typography-heading"
                  htmlFor="email-address"
                >
                  Email
                </label>
                <input
                  type="email"
                  name="email-address"
                  onChange={handleChange}
                  required={true}
                  className="w-full rounded-sm border border-solid border-gray-50 bg-transparent px-4 py-2.5 text-typography-body transition-colors duration-300 ease-linear focus:border-transparent focus:outline-none focus:ring-3 focus:ring-primary-500/50"
                />
              </div>

              <div>
                <label
                  className="mb-1 block font-body text-sm font-semibold text-typography-heading"
                  htmlFor="state"
                >
                  State
                </label>
                <select
                  name="state"
                  className="w-full rounded-sm border border-solid border-gray-50 bg-transparent px-4 py-2.5 text-typography-body transition-colors duration-300 ease-linear focus:border-transparent focus:outline-none focus:ring-3 focus:ring-primary-500/50"
                  onChange={handleChange}
                  required={true}
                >
                  <option value="">Select One...</option>
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </select>
              </div>
            </div>

            <textarea name="answers" className="hidden" />
            <input name="quote" className="hidden" />

            <div className="flex justify-center">
              <ButtonSolid type="submit" text="Submit" />
            </div>
          </div>
          <BackButton onClick={step7Back} />
        </div>
      </form>
    </>
  );
};

export default Form;
