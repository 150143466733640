import React from "react";

const BackButton = ({ onClick }) => {
  return (
    <button
      className="text-primary-900 group group absolute top-6 inline-flex items-center justify-center space-x-2.5 font-heading font-bold no-underline hover:text-primary-500 md:top-12"
      type="button"
      onClick={onClick}
    >
      <div className="relative flex h-9 w-9 items-center justify-center rounded-full bg-gradient-to-r from-secondary-500 to-primary-500">
        <div className="absolute inset-0 m-px rounded-full bg-gray-800 opacity-100 transition-opacity duration-300 ease-linear group-hover:opacity-0" />

        <i className="far fa-arrow-left z-10 text-lg text-primary-500 transition-all duration-300 ease-linear group-hover:text-white"></i>
      </div>

      <span className="text-sm font-bold md:text-base">Back</span>
    </button>
  );
};

export default BackButton;
